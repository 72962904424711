<template>

    <div class="ms-grid" v-if="getAcf">

      <hr class="divider" noshade>

      <div class="super-gap-bottom" v-if="countAssignedReminderPosts.length > 0">
        <section class="campus-reminders"
        id="campus-reminders"
        role="region"
        aria-label="Campus Reminders">
          <h1 class="campus-reminders-header"><FlagSVG/>Campus Reminders</h1>
          <div class="reminders-flex">
            <template
              v-if="postsReminder[index]"
            >
              <c-link
              v-for="r, index in countAssignedReminderPosts"
              :key="postsReminder[index].ID"
              class="reminder"
              v-bind="processUrl(postsReminder[index].acf.url)">
                <h2 class="reminder-title">
                  {{ postsReminder[index].title }}
                  <ArrowSVG/>
                </h2>
                <div class="reminder-text" v-html="postsReminder[index].acf.text">
                </div>
              </c-link>
            </template>
          </div>
        </section>
      </div>

      <div class="component"
      v-if="(getTodayNews && limitTodayNews.length > 0)
      || getAcademicDates
      || (getTodayEvents && eventIcal)">
        <aside class="home-lists"
        role="complementary"
        aria-label="News, Events, and Academic calendar">
          <div class="ms-row">
            <!-- Today News -->
            <div class="m-col-1-3 news-list" v-if="getTodayNews && limitTodayNews.length > 0">
              <h1 class="home-list-header">News</h1>
              <ul class="home-list">
                <li class="home-list-item" v-for="(item, index) in limitTodayNews" :key="index">
                  <a class="home-list-item-anchor" :href="item.permalink">
                    <h2 class="home-list-title" v-html="item.title"></h2>
                  </a>
                </li>
              </ul>
              <a class="button"
              :href="getHome.more_news_button_url"
              role="button"
              v-html="getHome.more_news_button_text">
              </a>
            </div>
            <!-- Today Events -->
            <div class="m-col-1-3 events-list" v-if="getTodayEvents && eventIcal">
              <h1 class="home-list-header">Events</h1>
              <ul class="home-list">
                <li class="home-list-item" v-for="(item, index) in eventIcal" :key="index">
                  <a class="home-list-item-anchor" :href="item.url">
                    <time class="home-list-date"
                    :datetime="item.start_date_html_format"
                    v-html="item.display_start_date">
                    </time>
                    <h2 class="home-list-title" v-html="item.title"></h2>
                    <p class="home-list-blurb" v-html="item.description"></p>
                  </a>
                </li>
              </ul>
              <a class="button"
              :href="getHome.more_events_button_url"
              role="button"
              v-html="getHome.more_events_button_text"></a>
            </div>
            <!-- Academic Dates -->
            <div class="m-col-1-3 academic-dates-list" v-if="getAcademicDates">
              <h1 class="home-list-header">Academic calendar</h1>
              <ul class="home-list">
                <li class="home-list-item" v-for="item in academicDates" :key="item.ID">
                  <time class="home-list-date"
                  :datetime="item.start_date"
                  v-html="item.start_date_display">
                  </time>
                  <h2 class="home-list-title" v-html="item.title"></h2>
                </li>
              </ul>
              <a class="button"
              :href="getHome.calendar_button_url"
              role="button"
              v-html="getHome.calendar_button_text">
              </a>
            </div>

          </div>
        </aside>
      </div>

    </div>

</template>

<script>

// Mixins
import processUrl from '@/mixins/processUrl';
import todaysDate from '@/mixins/todaysDate';

// SVG
import FlagSVG from '@/components/ui-components/svg/icon-flag.svg';
import ArrowSVG from '@/components/ui-components/svg/icon-arrow.svg';

const ical2json = require('ical2json');

export default {
  name: 'CampusReminders',
  components: {
    FlagSVG,
    ArrowSVG,
  },
  mixins: [processUrl, todaysDate],
  data() {
    return {
      postsReminder: [],
    };
  },
  computed: {
    getAcf() {
      return this.$store.state.acf;
    },
    getHome() {
      return this.getAcf.uic_home;
    },
    getAcademicDates() {
      return this.$store.state.academicDates;
    },
    getReminder() {
      return this.$store.state.postReminder;
    },
    getNumberOfNewsToDisplay() {
      return this.getHome.how_many_news_items_to_display;
    },
    getTodayNews() {
      return this.$store.state.todayNews;
    },
    limitTodayNews() {
      return this.getTodayNews.slice(0, this.getNumberOfNewsToDisplay);
    },
    getTodayEvents() {
      return this.$store.state.todayEvents;
    },
    academicDates() {
      const itemsToShow = this.getHome.how_many_calendar_items_to_show;
      const academicDates = this.getAcademicDates;
      const newArray = [];
      // Loop through all AcademicDates posts
      Object.keys(academicDates).forEach((i) => {
        const startDate = new Date(academicDates[i].acf.start_date);
        // Compare post start date to todays date: if date past: skip and check next post
        if (startDate <= this.todaysDate) { return; }
        const monthNames = [
          'January', 'February', 'March',
          'April', 'May', 'June', 'July',
          'August', 'September', 'October',
          'November', 'December',
        ];
        const day = startDate.getDate();
        const monthIndex = startDate.getMonth();
        const obj = {};
        obj.ID = academicDates[i].ID;
        obj.title = academicDates[i].title;
        const readableDate = academicDates[i].acf.start_date.replace(/\//g, '-');
        obj.start_date = readableDate;
        obj.start_date_display = `${monthNames[monthIndex]} ${day}`;
        // New array with all posts and their required + formated data
        newArray.push(obj);
      });
      // Sort all posts with start date
      const orderedDates = newArray.sort((a, b) => a.start_date > b.start_date);
      // Keep only the determined amount of posts
      const mostRecent = orderedDates.slice(0, itemsToShow);
      return mostRecent;
    },
    countAssignedReminderPosts() {
      const assignedPosts = this.getHome.campus_reminder;
      let number = 0;
      // Check if there are any assigned posts to Campus Reminders
      if (Object.keys(assignedPosts).length > 0) {
        Object.keys(assignedPosts).forEach((i) => {
          if (assignedPosts[i].reminder.ID
          && assignedPosts[i].reminder.ID != null) {
            number += 1;
            this.$store.dispatch('getPost', { id: assignedPosts[i].reminder.ID, type: 'postReminder' });
          }
        });
      }
      // Return reminder post count
      const array = Array.from(Array(number).keys());
      return array;
    },
    eventIcal() {
      const posts = [];
      try {
        const itemsToShow = parseInt(this.getHome.how_many_events_to_display, 10);
        const vevents = ical2json.convert(this.getTodayEvents).VCALENDAR[0].VEVENT;
        for (let i = 0; i <= vevents.length - 1; i += 1) {
          const hasFeatured = vevents[i].CATEGORIES.includes('Featured');
          let startDate;
          let endDate;
          if (vevents[i]['DTSTART;TZID=America/Chicago;VALUE=DATE']) {
            const startDateStr = vevents[i]['DTSTART;TZID=America/Chicago;VALUE=DATE']; // example: 20230423
            startDate = new Date(
              startDateStr.slice(0, 4), // year
              startDateStr.slice(4, 6) - 1, // month (0-based)
              startDateStr.slice(6, 8), // day
            );
            const endDateStr = vevents[i]['DTEND;TZID=America/Chicago;VALUE=DATE']; // example: 20230506
            endDate = new Date(
              endDateStr.slice(0, 4), // year
              endDateStr.slice(4, 6) - 1, // month (0-based)
              endDateStr.slice(6, 8), // day
            );
          } else if (vevents[i]['DTSTART;TZID=America/Chicago']) {
            const startDateStr = vevents[i]['DTSTART;TZID=America/Chicago']; // example: 20230502T210000
            startDate = new Date(
              startDateStr.slice(0, 4), // year
              startDateStr.slice(4, 6) - 1, // month (0-based)
              startDateStr.slice(6, 8), // day
              startDateStr.slice(9, 11), // hour
              startDateStr.slice(11, 13), // minute
              startDateStr.slice(13, 15), // second
            );
            const endDateStr = vevents[i]['DTEND;TZID=America/Chicago']; // example: 20230502T200000
            endDate = new Date(
              endDateStr.slice(0, 4), // year
              endDateStr.slice(4, 6) - 1, // month (0-based)
              endDateStr.slice(6, 8), // day
              endDateStr.slice(9, 11), // hour
              endDateStr.slice(11, 13), // minute
              endDateStr.slice(13, 15), // second
            );
          }
          if (hasFeatured && (startDate > this.todaysDate || endDate > this.todaysDate)) {
            const obj = {};
            const monthNames = [
              'January', 'February', 'March',
              'April', 'May', 'June', 'July',
              'August', 'September', 'October',
              'November', 'December',
            ];
            obj.url = vevents[i].URL;
            obj.title = vevents[i].SUMMARY;
            const strSlash = vevents[i].DESCRIPTION.replaceAll('\\\,', '\,');
            obj.description = strSlash;
            obj.start_date = startDate;
            obj.start_date_html_format = startDate.toString();
            obj.end_date = endDate;
            obj.display_start_date = `${monthNames[obj.start_date.getMonth()]} ${obj.start_date.getDate()}`;
            posts.push(obj);
            if (posts.length === itemsToShow) { break; }
          }
        }
      } catch {
        posts.length = 0;
        const obj = {};
        obj.title = 'Check back for upcoming events.';
        posts.push(obj);
      }
      return posts;
    },
  },
  watch: {
    getReminder(newVal) {
      if (newVal !== null && newVal !== undefined) {
        this.postsReminder.push(newVal);
        this.$store.commit('resetPost', { type: 'postReminder' });
      }
    },
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.divider {
  border-top: 8px solid $red;
  border-bottom: none;
  margin: 60px 0 100px;
}

.campus-reminders {
  .campus-reminders-header {
    @extend .homepage-section-header;
    padding-left: 57px;
    position: relative;
    color: $navy;
    font-size: 35px;
    font-weight: 700;
  }
  .reminders {
    fill: $red;
    height: 30px;
    left: 0px;
    position: absolute;
    top: 7px;
    width: 30px;
    vertical-align: middle;
  }
  .reminders-flex {
    @include clearfix();
    display: flex;
    flex-wrap: wrap;
    margin-right: -1rem;
  }
  .reminder {
    color: $charcoal;
    display: block;
    flex: 1 0 auto;
    float: left;
    margin-top: 20px;
    padding-right: 1rem;
    position: relative;
    text-decoration: none;
    width: 100%;

    @media (min-width: $vp3) {
      max-width: 50%;
      width: 50%;
    }

    @media (min-width: $vp4) {
      width: 25%;
    }
    &:hover,
    &:visited {
      color: $charcoal;
      text-decoration: none;
    }&:before {
      @include clickableBlock();
      width: calc(100% - 1rem);
    }
  }
  .reminder-title {
    color: $navy;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.36;
    margin-bottom: 4px;
    margin-top: 20px;
    padding-right: 20px;
    position: relative;
    .arrow {
      fill: $navy;
      height: 10px;
      @include arrowTransition();
      position: absolute;
      right: 5px;
      top: calc(0.5em - 5px);
      width: 10px;
    }
  }
  .reminder-text {
    @include bodyText();
    color: $gray-text;
    font-size: 18px;
  }
  .reminder:hover,
  .reminder:focus {
    outline: none;
    &:before {
      @include clickableBlockActive();
    }
    .arrow {
      @include arrowActiveState();
    }
  }
}

// News List - Events List - Academic Dates List

.home-lists {
  $distanceBetweenEachItem: 30px;
  .home-list-header {
    // @extend .homepage-section-header;
    border-bottom: 3px solid transparent;
    line-height: 1;
    margin-bottom: $distanceBetweenEachItem;
    padding-bottom: 16px;
    color: $navy;
    font-size: 35px;
    font-weight: 700;
    // letter-spacing: .25px;
  }

  $listsWithColors: ('news-list': $navy, 'events-list': $navy, 'academic-dates-list': $navy);

  @each $listType, $color in $listsWithColors {
    .#{$listType} {
      @media (max-width: $vp3) {
        margin-bottom: 48px;
      }
      .home-list-header {
        border-bottom-color: $color;
      }
      .home-list-kicker,
      .home-list-date {
        color: $red;
      }
      .button {
        @include button(#FFF, $color);
      }
      .home-list-item-anchor:hover {
        .home-list-title {
          color: $color;
        }
        .home-list-blurb {
          color: $color;
        }
      }
    }
  }
  .home-list {
    list-style: none inside;
  }
  .home-list-item {
    margin-bottom: $distanceBetweenEachItem;
  }
  .home-list-title {
    color: $gray-text;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.36;
    transition: color 0.4s;
  }
  .home-list-kicker {
    font-size: 16px;
    font-weight: 500;
  }
  .home-list-item-anchor,
  .home-list-item-anchor:hover,
  .home-list-item-anchor:visited {
    text-decoration: none;
  }

  /*.arrow
        fill #FFF
        height 10px
        margin-left 8px
        arrowTransition()
        width 10px

    .button:hover .arrow
        arrowActiveState()*/ // FIX ??
  .home-list-date {
    display: block;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    color: $red;
  }
  .home-list-blurb {
    @include bodyText();
    color: $gray-text;
    font-size: 18px;
    transition: color 0.4s;
  }
}

</style>
